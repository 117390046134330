import React from "react"
import Container from "../components/Container";
import Layout from "../components/Layout";
import * as styles from '../styles/home.module.css'

export default function notFound() {
    return (
        <div className={styles.backColor}>
            <Layout>
                <Container>
                    <h2>404</h2>
                    <p>Stranica koju ste tražili, nažalost nije pronađena.</p>
                </Container>
            </Layout>
        </div>
    )
}
